module.exports = [{
      plugin: require('/home/bkc/src/MurkWorks/blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/bkc/src/MurkWorks/blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-73379983-2"},
    },{
      plugin: require('/home/bkc/src/MurkWorks/blog/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/bkc/src/MurkWorks/blog/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    }]
